import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import {provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {provideAnimations} from "@angular/platform-browser/animations";
import {TinodeService} from "./services/tinode.service";
import {UserService} from "./services/user.service";
import {ApiService} from "./services/api.service";

export const appConfig: ApplicationConfig = {
  providers: [
      TinodeService,
      UserService,
      ApiService,
      provideRouter(routes),
      provideHttpClient(withInterceptorsFromDi()),
      provideAnimations(),
  ],
};
