import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

export const GeneralGuard: CanActivateFn = () => {
  const router = inject(Router);
  const cookieService = inject(CookieService);
  if (!cookieService.get('tinode_token')) {
    router.navigate(['/login'], { skipLocationChange: true }).then();
    return false;
  } else {
    return true;
  }
};
