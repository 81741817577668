import { Component, Inject, LOCALE_ID, Self } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { NgOnDestroy } from './services/onDestroy.service';
import { TinodeService } from './services/tinode.service';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/it';

@Component({
  standalone: true,
  imports: [RouterOutlet],
  providers: [NgOnDestroy],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  constructor(
    private tinodeService: TinodeService,
    private router: Router,
    @Inject(LOCALE_ID) private locale: string,
    @Self() private ngOnDestroy$: NgOnDestroy
  ) {
    moment.locale(this.locale);
    this.tinodeService.initConnection();
    this.ngOnDestroy$.subscribe().add(
      this.tinodeService.tinode?.onDataMessage.subscribe((message: any) => {
        const topic = this.tinodeService.tinode?.getTopic(message.topic);
        if (
          !message ||
          message?.head?.replaced ||
          this.router.url.indexOf('chats/') !== -1 ||
          topic?.getAccessMode().isMuted('mode')
        ) {
          return;
        }
        // const sound = new Howl({
        //   src: [
        //     '/assets/audio/notification.mp3',
        //     '/assets/audio/notification.m4r',
        //     '/assets/audio/notification.ogg',
        //     '/assets/audio/notification.wav',
        //   ],
        //   volume: 0.5,
        // });
        // Howler.volume(0.5);
        // sound.play();
      })
    );
  }
}
