import { Route } from '@angular/router';
import { PATH_URLS } from './const';
import { GeneralGuard } from './guards/general.guard';

export const routes: Route[] = [
  {
    path: '',
    redirectTo: PATH_URLS.chats,
    pathMatch: 'full',
  },
  {
    path: PATH_URLS.chats,
    canActivate: [GeneralGuard],
    loadComponent: () =>
      import('./layouts/general-layout.component').then(
        m => m.GeneralLayoutComponent
      ),
    loadChildren: () =>
      import('./layouts/general-layout-routing').then(
        m => m.CHAT_LAYOUT_ROUTES
      ),
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/login/login.component').then(m => m.LoginComponent),
  },
  {
    path: '**',
    loadComponent: () =>
      import('./pages/404/not-found.component').then(m => m.NotFoundComponent),
  },
  {
    path: '**',
    redirectTo: '404',
  },
];
